import { render, staticRenderFns } from "./homeStats.vue?vue&type=template&id=1d110a1e&scoped=true&"
import script from "./homeStats.vue?vue&type=script&lang=js&"
export * from "./homeStats.vue?vue&type=script&lang=js&"
import style0 from "./homeStats.vue?vue&type=style&index=0&id=1d110a1e&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d110a1e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VCol,VDivider,VRow})
