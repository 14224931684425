<template>
	<div class="stats pt-4">
		<v-row justify="space-between">
			<v-col cols="12" md="3" align="center" justify="center">
				<v-row>
					<v-col cols="12">
						<v-avatar size="175" class="pulse">
							<img
								src="@/assets/icons/everyone_my preferences_cropped.svg"
								alt="New Users Icon"
							/>
						</v-avatar>
					</v-col>
				</v-row>
				<h1>{{ statistics.interestedUserCount }}</h1>
				<h4>Registered Interested Parties</h4>
			</v-col>
			<v-divider vertical></v-divider>
			<v-col cols="12" md="3" align="center" justify="center">
				<v-row>
					<v-col cols="12">
						<v-avatar size="175" class="pulse">
							<img
								src="@/assets/icons/business listings_cropped.svg"
								alt="Business Listings Icon"
							/>
						</v-avatar>
					</v-col>
				</v-row>

				<h1>{{ statistics.postCount }}</h1>
				<h4>Businesses listed by Insolvency Practitioners</h4>
			</v-col>
			<v-divider vertical></v-divider>
			<v-col cols="12" md="3" align="center" justify="center">
				<v-row>
					<v-col cols="12">
						<v-avatar size="175" class="pulse">
							<img
								src="@/assets/icons/misc_send_cropped.svg"
								alt="Expressions of Interest Icon"
							/>
						</v-avatar>
					</v-col>
				</v-row>
				<h1>{{ statistics.interestCount }}</h1>
				<h4>Expressions of Interest Received</h4>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name: "homeStats",
	props: ["statistics"]
};
</script>

<style  lang="sass" scoped>
.pulse
	animation: shadow-pulse 1.5s infinite


@keyframes shadow-pulse
	0% 
		box-shadow: 0 0 0 0px rgba(0, 131, 166, 0.5)
	
	100% 
		box-shadow: 0 0 0 35px rgba(0, 131, 166, 0)
	
h1
	font-size: 3em

</style>