<template>
	<!-- container is not applied at a high level on this page in order to achieve sectioned background colouring (the light blue-white-light blue-white pattern) -->
	<div>
		<!-- The Glowing Blue Arrow In the Bottom Right -->
		<v-speed-dial fixed bottom right class="d-none d-sm-flex">
			<template v-slot:activator>
				<v-btn
					class="animate-flicker"
					outlined
					color="secondary"
					fab
					small
					@click="$vuetify.goTo(target, scrollOptions)"
				>
					<v-icon v-if="!isAtBottom">mdi-chevron-down</v-icon>
					<v-icon v-else>mdi-chevron-up</v-icon>
				</v-btn>
			</template>
		</v-speed-dial>

		<div class="banner">
			<router-link
				style="fontWeight: 300"
				:to="{ name: 'RegisterDirectory' }"
			>
				<div>
					<span
						>Join Thousands of Investors and Insolvency
						Practitioners.
						<strong style="fontWeight: 700"
							>REGISTER HERE TODAY</strong
						></span
					>
				</div></router-link
			>
		</div>

		<section class="hero">
			<h1>
				Matching Buyers and Sellers of Insolvent Businesses and
				Distressed Assets
			</h1>
			<h3>
				UK’s leading Insolvency Practitioners post their Insolvent
				Businesses and Assets for sale.
			</h3>
		</section>
		<main>
			<section
				id="homePosts"
				v-if="loadingPosts || visitor.homePosts.length > 0"
			>
				<div class="container">
					<div v-if="visitor.homePosts.length > 0">
						<h2 align="center">
							<span style="color:#f54e14">LIVE POSTS</span> on IP-BID.com
						</h2>
						<cardCarousel :posts="visitor.homePosts" />
					</div>

					<v-skeleton-loader
						ref="skeleton"
						type="image, card-heading"
						tile
						v-if="loadingPosts"
					></v-skeleton-loader>
				</div>
			</section>

			<section
				id="matchingCriteria"
				style="background: white"
				class="py-12 my-12"
			>
				<div class="container py-4 my-4" align="center">
					<h2>MATCHING CRITERIA</h2>
					<p class="matchingCriteriaText">
						Business failures are rising dramatically and IP-BID.com
						is also growing rapidly, regularly matching serious
						buyers and qualified sellers of distressed businesses
						instantly online via its new web-based insolvency
						marketplace. More than 5,000 potential buyers and their
						representatives have already registered to receive
						details of the latest insolvent businesses and
						distressed assets for sale. The UK’s leading insolvency
						practitioners post their insolvent businesses and assets
						for sale on the site, which then automatically matches a
						particular business for sale with potential buyers.
						These business opportunities can include companies in
						administration and pre-packs, as well as companies in
						receivership or liquidation with significant business
						assets for sale.
					</p>
					<img
						src="@/assets/icons/matching_criteria.gif"
						alt="Matching Criteria"
						style="width: 50vmin; margin:0 auto;"
						class="justify-center"
					/>
				</div>
			</section>

			<section class="py-12 my-12 container" id="buyerProfiles">
				<div align="center">
					<h1>BUYER PROFILES</h1>
					<v-row class="matchingCriteriaText">
						<v-col
							cols="6"
							md="4"
							v-for="(chunk, index) in chunkedBuyerProfiles(
								isMobile ? 2 : 3
							)"
							:key="index"
						>
							<ul>
								<li
									v-for="(profile, index) in chunk"
									:key="index"
								>
									<v-divider class="my-2"></v-divider>
									<p
										style="fontSize: 0.9em"
										v-html="profile"
									></p>
								</li>
							</ul>
						</v-col>
					</v-row>
				</div>
			</section>

			<section
				style="background:white"
				id="testimonialSection"
				class="py-12 my-12"
			>
				<div class="container py-6 my-6">
					<testimonials />
				</div>
			</section>

			<section
				v-if="visitor.homeStats != null"
				id="growingPlatform"
				class="py-12 my-12"
			>
				<div class="container">
					<h2 align="center">A GROWING PLATFORM</h2>
					<homeStats :statistics="visitor.homeStats" />
				</div>
			</section>
		</main>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";

import cardCarousel from "@/components/Visitor Components/cardCarousel.vue";
import homeStats from "@/components/Visitor Components/homeStats.vue";
import testimonials from "@/components/Visitor Components/testimonials.vue";

export default {
	name: "VisitorHome",
	components: {
		homeStats,
		testimonials,
		cardCarousel
	},
	data() {
		return {
			target: "#matchingCriteria",
			isAtBottom: false,
			loadingPosts: false,
			buyerProfiles: [
				"<strong>Multi-channel retailer</strong> of clothing, footwear, household and fishing tackle products",
				"<strong>Global 100 law firm </strong> with clients across 11 principal business sectors and practice areas that include strategic transactions, corporate finance and private equity",
				"Broad-based <strong>engineering firm</strong> with international client base",
				"Small-company <strong>investment specialist</strong> that claims to have provided private equity and venture capital to more UK SMEs than any other investor in the marketplace over the last five years",
				"Active <strong>construction company</strong> working in the public and private sectors",
				"UK and international <strong>intellectual property management firm</strong> that develops and commercialises new products for global markets, as well as advising technology companies, branded consumer product manufacturers and business services companies",
				"Unit trust of substantial landed estate focusing on <strong>commercial property</strong> opportunities",
				"Leading <strong>global private investment firm</strong> with more than £6 billion of capital under management",
				"Leading chartered surveyor specialising in <strong>corporate asset management</strong>",
				"<strong>Private equity firm</strong> that typically invests up to £3m in established people businesses with good management",
				"Leading <strong>leisure resort operator</strong>",
				"Independent <strong>private equity house</strong> focusing on acquisitions and investments in special situations that include turnaround opportunities and financial restructurings",
				"International <strong>asset consultant</strong> featuring a substantial corporate recovery and insolvency division",
				"<strong>Turnaround partnership</strong> that specialises in driving change through small to medium enterprises to stabilise a business and lay the foundations for achieving growth",
				"<strong>Real estate agent and broker</strong> also handling a substantial portfolio of its own property",
				"Private equity house with a diverse portfolio of investments, currently including companies in the <strong>medical, social care, property and furniture</strong> sectors",
				"<strong>Full service accountancy / business advisor</strong> with national and regional clients seeking acquisition opportunities",
				"Partnership of entrepreneurial individuals investing in <strong>private equity</strong> transactions with a strong track record across a range of industries, typically looking to invest £3-10 million of equity in each deal"
			],
			windowWidth: window.innerWidth
		};
	},
	created() {
		window.addEventListener("scroll", this.onScroll);

		this.loadingPosts = true;
	},
	async mounted() {
		window.onresize = () => {
			this.windowWidth = window.innerWidth;
		};

		this.$store.dispatch("retrieveHomeStats");
		await this.$store.dispatch("retrieveHomePosts");
		this.loadingPosts = false;
	},
	computed: {
		...mapState(["visitor"]),
		...mapGetters(["isLoggedIn", "role"]),
		isMobile() {
			return this.windowWidth < 960;
		},

		//Code to Allow The Automated Scrolling Button
		locationOfMatchingCriteria() {
			const el = this.$el.querySelector("#matchingCriteria");
			return el.offsetTop - el.offsetHeight + this.scrollOptions.offset;
		},
		locationOfBuyerProfiles() {
			const el = this.$el.querySelector("#buyerProfiles");
			return el.offsetTop - el.offsetHeight + this.scrollOptions.offset;
		},
		locationOfTestimonial() {
			const el = this.$el.querySelector("#testimonialSection");
			return el.offsetTop - el.offsetHeight + this.scrollOptions.offset;
		},
		locationOfGrowingPlatform() {
			const el = this.$el.querySelector("#growingPlatform");
			return el.offsetTop - el.offsetHeight + this.scrollOptions.offset;
		},
		sectionLocations() {
			//List needs to be ordered ascendingly
			return [
				{
					location: this.locationOfMatchingCriteria,
					id: "#matchingCriteria"
				},
				{
					location: this.locationOfBuyerProfiles,
					id: "#buyerProfiles"
				},
				{
					location: this.locationOfTestimonial,
					id: "#testimonialSection"
				},
				{
					location: this.locationOfGrowingPlatform,
					id: "#growingPlatform"
				}
			];
		},
		scrollOptions() {
			return {
				duration: 500,
				offset: 100,
				easing: "easeInOutCubic"
			};
		}
	},
	methods: {
		chunkedBuyerProfiles(columns) {
			const splitArray = (arr, chunkCount) => {
				const chunks = [];
				while (arr.length) {
					const chunkSize = Math.ceil(arr.length / chunkCount--);
					const chunk = arr.slice(0, chunkSize);
					chunks.push(chunk);
					arr = arr.slice(chunkSize);
				}
				return chunks;
			};
			return splitArray(this.buyerProfiles, columns);
		},
		//Method so that the frontend understands where to scroll to dependeing on where in the page the user is
		onScroll() {
			if (this.sectionLocations) {
				this.target = null;
				this.isAtBottom = false;

				let iteration = 0;
				while (!this.target) {
					if (
						window.scrollY <
						this.sectionLocations[iteration].location
					) {
						this.target = this.sectionLocations[iteration].id;
					} else if (
						window.scrollY >
						this.sectionLocations[this.sectionLocations.length - 1]
							.location
					) {
						this.isAtBottom = true;
						this.target = 0;
					}
					iteration++;
				}
			}
		}
	},
	beforeDestroy() {
		window.removeEventListener("scroll", this.onScroll);
	}
};
</script>

<style lang="sass" scoped>
.hero
	padding: 1vw
	display: flex
	justify-content: center
	align-items: center
	flex-direction: column
	text-align: center
	height: 40vh
	background: url('~@/assets/handshake.jpg') no-repeat center center 
	background-size: cover


	h1
		font-style: normal
		font-size: calc(1em + 1vmin)
		color: #eee !important
		letter-spacing: 0.03em
		line-height: 1
		margin-bottom: 10px
	
	h3
		font-style: normal
		font-size: calc(0.65em + 1vmin)
		color: #eee !important
		letter-spacing: 0.03em
		line-height: 1


.banner
	z-index: 5
	text-align: center !important
	padding: 1.5vmin 0.25vmin 1.5vmin 0.25vmin
	position: sticky
	background: #0083a6
	color: white
	top: 0
	font-size: calc(0.65em + 1.15vmin)
	transition: ease 0.25s
	
	@media screen and (min-width: 960px)
		line-height: 41px

	a
		color: white
		

.banner:hover
	background-color: #1e3354
	transition: ease 0.25s

.matchingCriteriaText
	max-width: 1200px


@keyframes flickerAnimation 
	0% 
		opacity: 1
	
	50% 
		opacity: 0.1
	
	100% 
		opacity: 1
	

@-o-keyframes flickerAnimation 
	0% 
	opacity: 1
	
	50% 
	opacity: 0.1
	
	100% 
	opacity: 1
	

@-moz-keyframes flickerAnimation 
	0% 
	opacity: 1
	
	50% 
	opacity: 0.1
	
	100% 
	opacity: 1
	

@-webkit-keyframes flickerAnimation 
	0% 
	opacity: 1
	
	50% 
	opacity: 0.1
	
	100% 
	opacity: 1
	

.animate-flicker 
	-webkit-animation: flickerAnimation 1.5s infinite ease
	-moz-animation: flickerAnimation 1.5s infinite ease
	-o-animation: flickerAnimation 1.5s infinite ease
	animation: flickerAnimation 1.5s infinite ease

</style>