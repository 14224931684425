<template>
	<div class="testimonial">
		<h2>WHAT PEOPLE ARE SAYING ABOUT US</h2>
		<v-carousel
			cycle
			light
			:show-arrows="false"
			hide-delimiter-background
			hide-delimiters
			interval="7000"
			class="py-3"
			width="960px"
			height="calc(340px - 7.5vw)"
			v-model="step"
		>
			<v-carousel-item
				v-for="testimonial in testimonials"
				:key="testimonial.id"
			>
				<v-row style="maxWidth: 960px; margin:0 auto;">
					<v-col cols="12" class="py-0 my-0">
						<p>
							<v-icon>mdi-format-quote-open</v-icon
							>{{ testimonial.text
							}}<v-icon>mdi-format-quote-close</v-icon>
						</p>
					</v-col>
					<v-col cols="12" class="py-0 my-0">
						<h4>
							{{ testimonial.name }}
						</h4>
					</v-col>
					<v-col cols="12" class="py-0 my-0">
						<span>{{ testimonial.company }}</span>
					</v-col>
				</v-row>
			</v-carousel-item>
		</v-carousel>
		<!-- Element for using the testimonial icons as carousel controller -->
		<v-card class="d-inline-flex" flat>
			<span v-for="item in testimonials" :key="item.id">
				<a>
					<img
						@click="step = item.id"
						class="mx-2 testIcon"
						alt="Testimonial icon"
						:src="
							step === item.id
								? require('@/assets/icons/testimonial.svg')
								: require('@/assets/icons/testimonialFaded.svg')
						"
					/>
				</a>
			</span>
		</v-card>
	</div>
</template>

<script>
export default {
	name: "testimonials",
	data() {
		return {
			step: 0,
			testimonials: [
				{
					id: 0,
					text:
						"What a great concept. As a potential acquirer, I can sit back and wait for the opportunities to come to me on my PC, already filtered according to my requirements. As they're all cases handled by licensed insolvency practitioners, I know that these are genuine distressed sales and can be completed quickly. And it's free to buyers!",
					name: "Richard Mitchell",
					company: "Director, C&V Data Management Services"
				},
				{
					id: 1,
					text:
						"IP-BID.com is an excellent service that does what it says on the tin. I certainly use it to test the market and identify potential buyers. It can also be beneficial in pre-pack situations, where speed and confidentiality are vital - helping insolvency practitioners to demonstrate that they have marketed a business adequately.",
					name: "Nick O'Reilly",
					company:
						"Partner, Business Recovery and Insolvency, HW Fisher & Company; former President of R3"
				},
				{
					id: 2,
					text:
						"With the establishment of SIP 16, it is the perfect tool. It shows clearly that you’ve marketed a business.",
					name: "Nick Simmonds",
					company: "Director, RSM Tenon"
				},
				{
					id: 3,
					text:
						"Our first posting on IP-BID.com was a pre-appointment case involving a property portfolio. I was worried that we might be inundated with timewasters but I was pleasantly surprised at the quality of expressions of interest. Posting the business was straightforward. It was a quick and easy way to test the market.",
					name: "Paul Whitwam",
					company: "Senior Manager, Tenon Recovery"
				},
				{
					id: 4,
					text: "Handling a small, specialist supplier of prescription medicines and health products for animals, I posted the business on IP-BID.com more in hope than anticipation, reckoning it was unlikely to achieve a match in a very short time scale. I received one expression from a professional adviser in the North of England who, it transpired, represented a client within the sector who held all the relevant licences. It was the perfect fit.",
					name: "Andrew McTear",
					company: "Partner, McTear Williams & Wood"
				}
			]
		};
	}
};
</script>

<style lang="sass" scoped>
.testimonial
	text-align: center

.testIcon
	width: calc(30px + 2.25vw)


</style>