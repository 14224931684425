<template>
	<div>
		<v-carousel
			cycle
			light
			continuous
			interval="4500"
			:show-arrows="groupedPosts.length > 1"
			:hide-delimiters="groupedPosts.length < 2"
			height="375"
			hide-delimiter-background
		>
			<v-carousel-item
				:key="index"
				v-for="(postSet, index) in groupedPosts"
			>
				<v-row justify="left">
					<v-col
						cols="12"
						:md="12 / postsPerCarousel"
						v-for="post in postSet"
						:key="post.businessId"
					>
						<buyerPostCard
							:cardWidth="cardWidth"
							:post="post"
							class="postCard"
						/>
					</v-col>
				</v-row>
			</v-carousel-item>
		</v-carousel>
	</div>
</template>

<script>
import buyerPostCard from "@/components/Buyer Components/buyerPostCard.vue";

const chunkArray = (array, size) => {
	let result = [];
	for (let i = 0; i < array.length; i += size) {
		let chunk = array.slice(i, i + size);
		result.push(chunk);
	}
	return result;
};

export default {
	name: "cardCarousel",
	props: ["posts"],
	data() {
		return {
			windowWidth: window.innerWidth
		};
	},
	mounted() {
		window.onresize = () => {
			this.windowWidth = window.innerWidth;
		};
	},
	components: {
		buyerPostCard
	},
	computed: {
		isMobile() {
			return this.windowWidth < 960;
		},
		isXL() {
			return this.windowWidth > 1900;
		},
		postsPerCarousel() {
			return this.isMobile ? 1 : this.isXL ? 3 : 2;
		},
		groupedPosts() {
			return chunkArray(this.posts, this.postsPerCarousel);
		},
		cardWidth() {
			let width;
			if (this.windowWidth <= 460 && this.windowWidth >= 400) {
				width = 380;
			} else if (this.windowWidth < 400) {
				width = 280;
			} else {
				width = 450;
			}
			return width;
		}
	}
};
</script>
